.title {
  margin-top: .5rem;
}

.date {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.day {
  font-size: 3rem;
  font-weight: 500;
  margin-right: .5rem;
}

.month,
.year {
  font-size: .9rem;
  text-align: right;
  text-transform: uppercase;
}
