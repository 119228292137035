@import '../../Styles/variables';
@import '../../Styles/mixins';

.container {
  @include flex-container-centered;

  background: url('../../Assets/img/welcome-bg.png') no-repeat center center;
  background-size: cover;
  flex: 1 1;
  font-size: 3rem;
  height: 100%;
  min-height: 100%;
  padding: 3rem;
  text-align: center;

  a {
    color: $white;
    line-height: 4rem;

    &:hover {
      color: $white;
    }
  }

  strong {
    font-weight: 700;
  }
}
