@import '../Styles/variables';

.wrapper {
  display: flex;

  main {
    flex-grow: 1;
    position: relative; // important for the home map
  }
}

