@import '../../Styles/variables';
@import '../../Styles/mixins';

.container {
  @include view;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  flex: 1 1;
  flex-direction: column;

  @media(min-width: $tablet) {
    flex-direction: row;
  }
}

.col {
  flex: 1 1;

  @media(min-width: $tablet) {
    width: 50%;
  }
}
