@import '../../Styles/variables';

.networkErrorView {
  align-items: center;
  background: url('../../Assets/img/welcome-bg.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.content {
  background: $network-error-content-bg;
  padding: 3rem;
  text-align: center;
}

.logo {
  max-width: 100%;
}
