html {
  height: 100%;
}

body {
  font-size: 16px;
  margin: 0;
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

/* This lines are really important! Please do not edit below this point! */
.canvas-container {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}
