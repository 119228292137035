.growthWrapper {
  position: relative;
}

.overlay {
  left: 0;
  position: absolute !important;
  top: 0;
}

.divider {
  margin-top: 2rem !important;
}

.categoriesList {
  text-align: left;

  > div {
    padding: .8rem 0 !important;
  }
}

.systemMonitor {
  margin-bottom: 2rem;
}

.label {
  display: block;
  margin: 1rem 0;
}

.legend {
  display: block;
  max-width: 100%;
}
