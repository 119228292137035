@import '../../Styles/variables';

.container {
  //
}

.row {
  display: flex;
  flex-direction: column;

  @media(min-width: $sm-desktop) {
    flex-direction: row;
  }
}

.sidebar {
  padding: 2rem;

  @media(min-width: $sm-desktop) {
    width: 25%;
  }
}

.content {
  @media(min-width: $sm-desktop) {
    width: 75%;
  }
}
