@import '../../Styles/variables';
@import '../../Styles/mixins';

.container {
  @include flex-container-centered;
  background-color: $white;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 2rem;
  text-align: center;
}

.form {
  max-width: 400px;

  h1 {
    font-weight: 300 !important;
    margin-top: 2rem !important;

    @media(min-width: $tablet) {
      margin-top: 0 !important;
    }
  }

  label {
    font-size: 1.2rem !important;
    margin-top: 2rem !important;
  }

  input {
    &[type='text'],
    &[type='password'] {
      border: 0 solid !important;
      border-bottom: 1px solid $login-input-border-fg !important;
      border-radius: 0 !important;
      text-align: center !important;
    }

    &:focus {
      border-bottom: 1px solid $login-input-focus-border-fg !important;
    }
  }
}


.submitRow {
  margin-top: 2rem !important;
}
