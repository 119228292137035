@import '../../Styles/variables';

.stationCell {
  text-align: center !important;
  @media(min-width: $tablet) {
    text-align: left !important;
  }
}

.sortIcon {
  @media(min-width: $tablet) {
    float: right !important;
  }
}
