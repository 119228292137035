@import '../../Styles/variables';

.footer {
  background-image: linear-gradient(11deg, $footer-gradient-start-bg 38%, $footer-gradient-end-bg);
  color: $footer-fg;
  font-size: 18px;
  padding: 1rem 2rem;
  text-align: center;
}

.link {
  color: $footer-fg;
}
