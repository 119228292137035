@import '../../Styles/variables';

.navbar {
  border-radius: 0 !important;
  box-shadow: 0 0 0 !important;
  font-size: 1.2rem !important;
  margin-bottom: 0 !important;

  img {
    height: 40px;

    @media(min-width: $tablet) {
      height: 50px;
    }
  }

  .item {
    &::before,
    &::after {
      width: 0 !important; // removes items separators
    }
  }
}

.userIcon {
  color: $navbar-user-icon-fg;
}

.menuIcon {
  color: $navbar-plus-icon-fg;
  font-size: 1.8rem !important;
  position: relative;
  top: 5px;
}

.logoutIcon {
  font-size: 1.8rem !important;
}

.itemMobile {
  background: $navbar-mobile-menu-bg !important;
  border-radius: 0 !important;
  font-size: 1.2rem;
  padding: 1rem 2rem 1rem 1.5rem !important;
}

.dropdownHeader {
  background: $navbar-mobile-menu-bg !important;
  margin-top: 0 !important;
  min-width: 200px !important;
  padding: 1rem !important;
}
